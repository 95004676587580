import { ReapitConnectBrowserSession } from "@reapit/connect-session";

// Needs to be a singleton as the class is stateful
export const reapitConnectBrowserSession = new ReapitConnectBrowserSession({
  connectClientId: process.env.REACT_APP_CONNECT_CLIENT_ID || '',
  connectOAuthUrl: "https://connect.reapit.cloud",
  connectUserPoolId: process.env.REACT_APP_CONNECT_USER_POOL_ID || '',
  connectLoginRedirectPath: "",
  connectLogoutRedirectPath: "https://platform.reapit.cloud",
});
