import React from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import ReapitConnect from "./pages/reapit-connect/reapit-connect";
import Home from "./pages/home/home";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<ReapitConnect />} />
        <Route path="/home" element={<Home />} />
      </Routes>
    </div>
  );
}

export default App;
