import { useReapitConnect } from "@reapit/connect-session";
import { reapitConnectBrowserSession } from "../../core/connect-session";
import { useEffect } from "react";

export type Props = {};

export const ReapitConnect: React.FunctionComponent<Props> = () => {
  const { connectSession } = useReapitConnect(reapitConnectBrowserSession);
  const reapitV1URL = process.env.REACT_APP_REAPIT_URL;

  useEffect(() => {
    if (!connectSession) return;
    const data = btoa(encodeURIComponent(JSON.stringify(connectSession)));

    window.location.href = reapitV1URL + "/reapit-connect-redirect/" + data;
  }, [connectSession]);

  return <>Reapit Connect</>;
};

export default ReapitConnect;
